<template>
  <section class="mb-10">   
        <v-layout justify-space-around justify-center class="contact-layout">
            <v-flex xs9 sm9 offset-sm0 class="" align="center" justify="center" >
                    <v-card flat tile class="mt-4" color=""  >
                         <v-card-title primary-title class="justify-center">
                                    <h3 class="zamu-heading transact-heading product-heading solutions-heading careers-header px-3">LET'S GROW TOGETHER</h3><br />
                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                        <v-card-text align="center" justify="center">
                            Join Zamupay and help power growth for a new generation of businesses across Africa.

                            <div class="text-center pt-6">
                                <v-btn  class="ma-2" outlined color="indigo">
                                  No Job Openings
                                </v-btn>
                            </div>


                        </v-card-text>
                    </v-card>
            </v-flex>
        </v-layout>
  </section>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';

export default {
    name: 'careerList',
    mixins: [coreMixin, colorMixin]

}
</script>

<style scoped>
.careers-header {
    font-size: 37px !important;
}
</style>